<template>
    <div class="lg:pt-10 lg:pb-6 lg:border-b">
        <Navbar :inverse="true" />
    </div>
    <div class="font-nunito px-6 pt-8 lg:py-24">
        <h3 class="text-xl lg:w-1/2 mx-auto font-bold mb-8">
            Are you looking for a Stay-Home Notice (SHN) hotel in Singapore?
        </h3>
        <p class="text-base lg:w-1/2 mx-auto lg:text-lg font-light leading-relaxed lg:leading-normal">
            The Snooze Hotel @ Marine Parade now offers a special package for those who are required to
            serve a 10-day Stay-Home Notice (SHN) or a 7-day Stay-Home Notice (SHN). Prices are as low as
            $95 per room. HURRY UP and make a reservation with us today by emailing us or talk to us on
            WhatsApp.<br><br>
            You are advised to check out this <a href="https://safetravel.ica.gov.sg/shn-and-swab-summary" rel="noopener" target="_blank" class="underline text-blue-500">link</a> for the latest advisory. Requirements might differ for
            different country and region so do take the time to go through all the details.<br><br>
            <router-link to="/contact/The%20Snooze%20Hotel" class="text-white px-4 py-1 duration-100 font-nunito tracking-wider ease-linear transition hover:bg-white border hover:border-gray-300 hover:text-black bg-black rounded-full">Contact us ⟶</router-link>
        </p>

        <div class="grid grid-cols-4 gap-10 lg:gap-0 pt-16 lg:pt-28 lg:px-20">
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/sprayBottle.png" alt="Spray bottle" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">all rooms are sanitised thoroughly</h4>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/meals.png" alt="Meals" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">top up for meals</h4>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/kettle.png" alt="Kettle" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">electric kettle and complimentary coffee and tea</h4>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/laundry.png" alt="Laundry" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">$10 per wash and dry</h4>
            </div>
        </div>

        <div class="grid grid-cols-4 lg:grid-cols-3 gap-10 lg:gap-0 pt-10 lg:pt-20 lg:px-32">
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/wifi.png" alt="Wi-Fi" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">highspeed WiFi</h4>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/frontDesk.png" alt="24 hours front desk" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">24 hours front desk service</h4>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-1">
                <img src="/assets/shn/cancellation.png" alt="free cancellation" class="mx-auto">
                <h4 class="text-center w-2/3 lg:w-1/2 mx-auto mt-5">FREE CANCELLATION (3 days prior to arrival)</h4>
            </div>
        </div>

        <img src="/assets/shn/sticky.jpg" alt="Sticky note" id="stickyNote" class="transform rotate-6 my-10 lg:mt-20 mx-auto lg:ml-auto lg:mr-20" />
    </div>
</template>

<script>
import Navbar from "../components/Navbar";

export default {
    name: "SHN",
    components: {
        Navbar
    },
    mounted() {
        document.title = "Stay Home Notice (SHN) - The Snooze Hotel @ Marine Parade"
        document.querySelector('meta[name="description"]').setAttribute("content", "The Snooze Hotel @ Marine Parade now offers a special package for those who are required to serve a 10-day Stay-Home Notice (SHN) or a 7-day Stay-Home Notice (SHN).");
    }
}
</script>

<style scoped>
.grid img {
    height: 100px;
}

#stickyNote {
    height: 350px;
}
</style>