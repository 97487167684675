import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import Contact from "../views/Contact";
import SHN from "../views/SHN";

const routes = [
    { path: "/", component: Home },
    { path: "/contact/:location", component: Contact },
    { path: "/shn", component: SHN }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
        return { 
          top: 0,
          behavior: 'smooth'
        }
    }
  }
});

export default router;