<template>
    <div id="landingDiv" class="lg:pt-10">
        <Navbar />
        <div class="text-center" id="landingLogos">
            <img src="/assets/snoozeHotelLogoColored.png" class="mx-auto inline-block lg:mx-6" alt="Snooze Hotel Logo">
            <img src="/assets/snoozeInnLogo.png" class="mx-auto inline-block lg:mx-6" alt="Snooze Inn Logo">
        </div>
        <video class="absolute top-0" playsinline autoplay loop muted>
            <source src="/assets/landingVideo.mp4" type="video/mp4">
        </video>
    </div>
    
    <div id="about" class="bg-white text-black text-center">
        <div class="pt-8 lg:pt-14">
            <h2 class="uppercase font-raleway font-extralight text-3xl lg:text-4xl tracking-wide">Staying With Us</h2>
            <div class="border-black border-b-4 w-20 mx-auto mt-3 mb-8 lg:mt-4 lg:mb-10"></div>
            <p class="font-nunito text-base mx-6 lg:text-lg font-light leading-relaxed lg:leading-normal">
                Whether you’re here for business or pleasure, you’ve come to the right place.<br>
                Located at 3 different locations, we offer comfortable and<br>convenient stay at competitive rates.<br>
                Solo travelers and family alike, we have various rooms to suit your individual needs.<br>
                Make sure to book your reservation today - we hope to <span class="font-semibold">see you soon</span>!<br>
            </p>
        </div>
    </div>  

    <div id="locations" class="grid grid-cols-4">
        <HomeLocation :locationLink="'https://hotels.cloudbeds.com/reservation/J9YmSh'" :backgroundImageCSS="`background-image: url('/assets/snoozeInnBugis.jpg') `" :hotelName="'The Snooze Hotel'" :locationTitle="'@Bugis'" :desc="'Situated 5 minutes away from Bugis MRT, exploring other parts of Singapore has never been this convenient.'" class="col-span-4 lg:col-span-1" />
        <HomeLocation :locationLink="'https://hotels.cloudbeds.com/reservation/kbAaNa'" :backgroundImageCSS="`background-image: url('/assets/theSnoozeHotel.jpg')`" :hotelName="'The Snooze Hotel'" :locationTitle="'@Marine Parade'" :desc="'Suitable for leisure travellers and couples at an affordable price. Private and family rooms available.'" class="col-span-4 lg:col-span-1" />
        <HomeLocation :locationLink="'https://hotels.cloudbeds.com/reservation/hL25Hw'" :backgroundImageCSS="`background-image: url('/assets/snoozeInnDicksonRoad.jpg')`" :hotelName="'Snooze Inn'" :locationTitle="'@Dickson Road'" :desc="'A simple and no-frills accommodation, suitable for backpackers and solo travellers on a budget. Family rooms available too.'" class="col-span-4 lg:col-span-1" />
        <HomeLocation :locationLink="'https://hotels.cloudbeds.com/reservation/lfAQ20'" :backgroundImageCSS="`background-image: url('/assets/snoozeInnDunlopStreet.jpg') `" :hotelName="'Snooze Inn'" :locationTitle="'@Dunlop Street'" :desc="'A simple and no-frills accommodation, suitable for backpackers and solo travellers on a budget. Family rooms available too.'" class="col-span-4 lg:col-span-1" />
    </div>

    <div id="promotions" class="relative pt-20 lg:pb-28 pb-24">
        <div class="flex flex-col">
            <h5 class="font-raleway bg-white-90 w-auto mx-auto text-pink-600 uppercase text-center text-4xl lg:text-5xl py-5 px-8 font-semibold tracking-wide">Book With Us</h5>
            <p class="font-nunito mt-10 lg:mt-5 text-white lg:text-xl font-extrabold text-center lg:text-right uppercase w-auto lg:mx-auto bg-black-70 lg:px-20 px-4 mx-2 py-8 lg:py-6"><a href="#locations" class="text-yellow-300 text-xl lg:text-2xl hover:underline">Book direct</a> on our website to get the best deals! Up to<br>30% off and other promotions available.</p>
        </div>
        <video class="absolute top-0 w-full h-full" playsinline autoplay loop muted>
            <source src="/assets/promotionVideo.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
import Navbar from "../components/Navbar";
import HomeLocation from "../components/HomeLocation";

export default {
    name: "Home",
    components: {
        Navbar,
        HomeLocation
    },
    mounted() {
        document.querySelectorAll(".bookNow").forEach((btn) => {
            btn.classList.add("hidden");
        });

        window.addEventListener("scroll", this.aboutScroll);
    },
    methods: {
        aboutScroll() {
            const vh = (window.scrollY / window.innerHeight).toFixed(2);
            document.querySelector("#about").style.backgroundColor = `rgba(255,255,255,${Math.abs(1.3-vh)})`;
        }
    },
    unmounted() {
        window.removeEventListener("scroll", this.aboutScroll);
    }
}
</script>

<style scoped>
#landingDiv {
    height: 65vh;
}

#landingDiv video {
    height: 130vh;
    width: 100vw;
    object-fit: cover;
    margin-top: -15vh;
    z-index: -10;
}

#landingLogos {
    margin-top: 15vh;
}

#landingLogos img {
    height: 80px;
}

@media (min-width: 1024px) { 
    #landingLogos img {
        height: 120px;
    }
}

#about {
    height: 50vh;
}

#promotions video {
    z-index: -10;
    object-fit: cover;
}
</style>

<style>
.bg-white-90 {
    background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-70 {
    background-color: rgba(0, 0, 0, 0.7);
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
</style>