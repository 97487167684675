<template>
    <div :class="!!locationLink ? 'hover:grayscale-0' : ''" class="filter bg-gray-200 transition-filter ease-linear lg:grayscale homeLocation h-96 lg:h-screen" @mouseover="showBtn" @mouseleave="hideBtn" :style="backgroundImageCSS">
        <div @mouseover="showBtn" class="rounded-lg hotelInfo bg-white-90 text-center mx-6 mt-14 mt-90percent lg:mx-20 py-4">
            <h3 :class="hotelName === 'Snooze Inn' ? '' : ''" class="font-raleway text-2xl uppercase font-medium tracking-wider">{{ hotelName }}</h3>
            <p :class="hotelName === 'Snooze Inn' ? '' : ''" class="font-raleway text-xl font-medium tracking-wide">{{ locationTitle }}</p>
            <p class="font-nunito mx-10 mt-5 text-gray-500">{{ desc }}</p>
            <div class="locationBtn lg:invisible mt-5 mb-2">
                <a v-if="!!locationLink" rel="noopener" target="_blank" :href="locationLink" class="text-white px-4 py-1 duration-100 font-nunito tracking-wider mx-4 ease-linear transition hover:bg-white hover:text-black bg-black rounded-full">Book Now ⟶</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeLocation",
    props: {
        backgroundImageCSS: String,
        hotelName: String,
        locationTitle: String,
        locationLink: String,
        desc: String
    },
    methods: {
        showBtn(e) {
            e.target.querySelector(".locationBtn").classList.remove("lg:invisible");
        },
        hideBtn(e) {
            e.target.querySelector(".locationBtn").classList.add("lg:invisible");
        }
    }
}
</script>

<style scoped>
.homeLocation {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media (min-width:1025px) {
    .mt-90percent {
        margin-top: 90%;
    }
}

</style>