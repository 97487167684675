<template>
  <router-view />
  <Footer />
</template>

<script>
import Footer from  "./components/Footer";

export default {
  name: 'App',
  components: {
    Footer
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background: #FEF3C7;
  color: #1E40AF;
}
</style>
