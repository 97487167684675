<template>
    <form id="contactForm" class="font-nunito mx-auto rounded-lg" @submit.prevent>
        <input type="text" v-model="contactName" placeholder="Name" id="contactName" name="contactName" class="mb-3 border border-gray-300 focus:ring-black focus:ring-1 focus:outline-none block w-full px-3 py-2 text-sm font-light rounded-xl" />
        
        <input type="text" v-model="email" id="email" placeholder="Email" name="email" class="mb-3 border border-gray-300 focus:ring-black focus:ring-1 focus:outline-none block w-full px-3 py-2 text-sm font-light rounded-xl" />

        <select name="selectedLocation" v-model="selectedLocation" class="mb-3 border border-gray-300 focus:ring-black focus:ring-1 focus:outline-none block w-full px-3 py-2 text-sm font-light rounded-xl">
            <option selected value="The Snooze Hotel">The Snooze Hotel</option>
            <option value="Snooze Inn @ Dickson Road">Snooze Inn @ Dickson Road</option>
            <option value="Snooze Inn @ Dunlop Street">Snooze Inn @ Dunlop Street</option>
        </select>

        <textarea id="message" v-model="message" name="message" placeholder="Your Message" rows="8" class="border border-gray-300 focus:ring-black focus:ring-1 focus:outline-none block w-full px-3 py-2 text-sm font-light rounded-xl" />

        <div class="text-center mt-8">
            <p v-show="!submitted" class="text-red-500 text-sm text-center my-5"><i v-show="errorMessage" class="fas fa-exclamation mr-2"></i>{{ errorMessage }}</p>
            <p v-show="submitted" class="text-green-500 text-sm text-center my-5">Your message has been sent. We will get back to you shortly.</p>
            <button v-if="!submitted" @click="handleSubmit" class="w-full py-1 uppercase hover:bg-black hover:text-white border border-black rounded-xl font-extrabold ease-in-out transition">Submit</button>
            <button v-if="submitted" class="opacity-80 cursor-not-allowed w-full py-1 uppercase bg-black border border-black rounded-xl font-extrabold text-white">Submitted</button>
        </div>
    </form>

</template>

<script>
import validator from "validator";

async function sendMail ({contactName, selectedLocation, phone, email, message}) {
  const mailData = {
    from: 'contact@caine.sg',
    to: 'contact@snooze-inn.com',
    replyTo: email,
    subject: `New message from ${contactName} about ${selectedLocation}`,
    text: `You have received a message from ${contactName} - ${phone} - ${email} - ${selectedLocation}. Message: ${message}`,
    html: `
      <p>Dear Snooze Inn,</p>
      <p>You got a new message from ${contactName} about ${selectedLocation}</p>
      <p>${email}</p>
      <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">${message}</p>
    `
  }

  const res = await fetch("https://www.caine.sg/api/contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(mailData)
  })

  return res.status === 200
}

export default {
    name: "ContactForm",
    data() {
        return {
            submitted: false,
            contactName: "",
            email: "",
            message: "",
            errorMessage: "",
            selectedLocation: ""
        }
    },
    beforeMount() {
        this.selectedLocation = this.$route.params.location;
    },
    watch: {
        "$route.params.location": function () {
            this.selectedLocation = this.$route.params.location;
        }
    },
    methods: {
        async handleSubmit() {
            this.submitted = false;

            if (this.contactName === "") {
                this.errorMessage = "Please enter your name.";
            } else if (!validator.isEmail(this.email)) {
                this.errorMessage = "Please enter a valid email.";
            } else if (this.message === "") {
                this.errorMessage = "Do enter a message."
            } else {
              if (await sendMail(this)) {
                this.contactName = "";
                this.email = "";
                this.message = "";
                this.errorMessage = "";
                this.selectedLocation = "";
                
                this.submitted = true;
              } else {
                this.errorMessage = "An error occurred while sending your message, try emailing us at contact@snooze-inn.com instead."
              }
            }
        }
    }
}
</script>

<style scoped>
</style>