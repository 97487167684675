<template>
    <!-- Mobile navbar -->
    <div class='z-50 flex relative py-5 px-8 lg:hidden'>
        <button aria-label="navbarMenu" class='z-50 ml-auto' :class="[ inverse ? 'text-black' : 'text-white' ]">
            <i :class="[ activeMobileNav ? 'invisible' : '' ]" @click='toggleMobileNav' class='fas fa-lg fa-bars align-middle'></i>
        </button>
    </div>
    <div id="mobileMenu" :class="[ activeMobileNav ? '' : 'hidden' ]" class='bg-white-90 z-50 absolute top-0 text-center w-full text-black font-inter font-extralight animate__animated animate__faster animate__slideInDown pr-2 flex flex-col'>
        <i :class="[ !activeMobileNav ? 'hidden' : '' ]" @click='toggleMobileNav' class='text-right pt-6 px-7 fas fa-lg text-black fa-times align-middle'></i>
        <div class="mb-1"><router-link class="px-2 hover:underline hover:tracking-wider" to="/#">Home</router-link></div>
        <div class="my-1"><a class="px-2 hover:underline hover:tracking-wider" href="/#promotions">Good Stuff</a></div>
        <div class="my-1"><router-link class="px-2 hover:underline hover:tracking-wider" to="/shn">SHN</router-link></div>
        <div class="my-1"><router-link class="px-2 hover:underline hover:tracking-wider" to="/contact/The%20Snooze%20Hotel">Contact Us</router-link></div>
        <router-link to="/#locations" class="px-4 text-center mt-5 mb-4 mx-auto text-white py-1 duration-100 tracking-wider ease-linear transition hover:bg-white hover:text-black bg-black rounded-full">Book Now ⟶</router-link>
    </div>

    <!-- Normal navbar -->
    <div id='nav' class='font-inter font-extralight z-50 bg-transparent hidden lg:block'>
        <div class="navLinks text-lg flex justify-evenly mr-10">
            <router-link to="/#" class="w-1/5 text-center duration-200 ease-linear transition my-1 hover:underline hover:tracking-wider">Home</router-link>
            <a href="/#promotions" class="w-1/5 text-center duration-200 ease-linear transition my-1 hover:underline hover:tracking-wider">Good Stuff</a>
            <router-link to="/shn" class="w-1/5 text-center duration-200 ease-linear transition my-1 hover:underline hover:tracking-wider">SHN</router-link>
            <router-link to="/contact/The%20Snooze%20Hotel" class="w-1/5 text-center duration-200 ease-linear transition my-1 hover:underline hover:tracking-wider">Contact Us</router-link>
            <router-link to="/#locations" :class="[ inverse ? 'text-black hover:bg-black hover:text-white' : 'text-white hover:bg-white hover:text-black bg-black' ]" class="text-center w-1/5 py-1 duration-100 tracking-wider mx-4 ease-linear transition rounded-full">Book Now ⟶</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            activeMobileNav: false
        }
    },
    props: {
        inverse: Boolean
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            const nav = document.querySelector('#nav');
            if (window.scrollY) {
                nav.classList.add('navbar-shadow');
            } else {
                nav.classList.remove('navbar-shadow');
            }
        },
        toggleMobileNav() {
            this.activeMobileNav = !this.activeMobileNav;
        },
        showLocations() {
            document.querySelector("#locations").classList.add("text-yellow-300");
            document.querySelector("#locationsContainer").classList.remove("hidden");
        },
        toggleMobileLocations() {
            document.querySelector("#mobileLocations").classList.toggle("text-yellow-300");
            document.querySelector(".fa-chevron-down").classList.toggle("rotate-180");
            document.querySelector("#mobileLocationsContainer").classList.toggle("hidden");
        },
        hideLocations() {
            document.querySelector("#locations").classList.remove("text-yellow-300")
            document.querySelector("#locationsContainer").classList.add("hidden");
        }
    }
}
</script>

<style scoped>
#nav {
    margin-left: 38vw;
}

.navLogo {
    height: 60px;
}

#placeholderMobileMenu {
    height: 64px;
}
</style>