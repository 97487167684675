<template>
    <div class="relative px-5 lg:px-20 pt-20 pb-20 font-light grid grid-cols-5">
        <div class="col-span-5 lg:col-start-2 order-2 lg:order-1 lg:col-span-2 mt-8 lg:mt-4">
            <div class="flex flex-wrap">
                <a href="/" class="mr-3 my-auto"><img src="/assets/snoozeInnLogo.png" alt="Logo" class="footerLogos" /></a>
                <a href="/" class="mt-2 lg:mt-3 lg:ml-2 my-auto"><img src="/assets/snoozeHotelLogoColored.png" alt="Logo" class="footerLogos" /></a>
            </div>
            <p class="text-sm font-nunito uppercase tracking-widest mt-12 lg:mt-16">© 2021 Snooze Inn Pte Ltd.<br> all rights reserved.</p>
        </div>
        
        <div class="ml-2 mt-2 lg:mt-0 lg:ml-0 col-span-5 order-1 lg:order-2 lg:col-span-1">
            <ul>
                <h5 class="text-lg mb-2 font-semibold uppercase tracking-wider font-raleway">Get in touch</h5>
                <div class="items-center flex mt-3 mb-5 lg:my-2">
                    <a rel="noopener" target="_blank" class="duration-100 ease-linear transition opacity-70 hover:opacity-100 my-auto" href="https://www.facebook.com/"><img style="height: 30px; width: 30px;" src="/assets/facebookLogo.png" alt="Facebook logos" /></a>
                    <a rel="noopener" target="_blank" class="duration-100 ease-linear transition ml-3 opacity-70 hover:opacity-100 my-auto" href="https://www.instagram.com/"><img style="height: 34px; width: 34px;" src="/assets/instagramLogo.png" alt="Instagram logos" /></a>
                </div>
                <li class="my-3"><router-link class='font-nunito font-light hover:underline hover:tracking-wider duration-200 ease-linear transition' to='/contact/The%20Snooze%20Hotel'>The Snooze Hotel</router-link></li>
                <li class="my-3"><router-link class='font-nunito font-light hover:underline hover:tracking-wider duration-200 ease-linear transition' to='/contact/Snooze%20Inn%20%40%20Dickson%20Road'>Snooze Inn @ Dickson Road</router-link></li>
                <li class="my-3"><router-link class='font-nunito font-light hover:underline hover:tracking-wider duration-200 ease-linear transition' to='/contact/Snooze%20Inn%20%40%20Dunlop%20Street'>Snooze Inn @ Dunlop Street</router-link></li>
            </ul>
        </div>

        <a href="#" style="height: 45px; width: 45px;" class="hover:-translate-y-1 transform lg:bottom-8 lg:right-10 bottom-4 right-5 flex items-center inline-block absolute text-black bg-white rounded-full"><i class="text-3xl fas fa-angle-up mx-auto"></i></a>
        
        <video class="absolute top-0 h-full w-full" playsinline autoplay loop muted>
            <source src="/assets/landingVideo.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
video {
    z-index: -10;
    object-fit: cover;
    object-position: bottom;
}

.footerLogos {
    height: 80px;
}

@media (min-width: 1024px) { 
    .footerLogos {
        height: 120px;
    }
}

</style>