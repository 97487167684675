<template>
    <div class="lg:pt-10 lg:pb-6 lg:border-b">
        <Navbar :inverse="true" />
    </div>
    <h3 class="text-center text-6xl tracking-wider pt-8 lg:pt-20 uppercase font-trend">Hello.</h3>
    <div class="grid grid-rows-2 mt-4 lg:mt-6 lg:mb-6">
        <div class="row-span-1 grid grid-cols-2 lg:px-56">
            <div class="col-span-2 lg:col-span-1 m-16 lg:w-3/4 mx-auto">
                <h5 class="text-center text-3xl font-raleway mb-6 lg:mb-10">Write us an email!</h5>
                <ContactForm />
            </div>

            <div class="col-span-2 lg:col-span-1 mb-8 lg:m-16 lg:w-2/3 text-center">
                <h5 class="text-3xl font-raleway mb-6 lg:mb-10">or call us :)</h5>
                <div class="">
                    <h4 class="font-nunito uppercase font-semibold text-lg lg:text-xl mb-1">The Snooze Hotel</h4>
                    <ul class="font-nunito font-light flex justify-between flex-col py-1">
                        <li><i class="align-middle fas fa-phone-alt"></i><a class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="tel:+6566655511">+65 6665 5511</a></li>
                        <li><i class="align-middle text-lg fab fa-whatsapp"></i><a rel="noopener" target="_blank" class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="https://wa.me/+6589500269">+65 8950 0269</a></li>
                        <li><i class="align-middle fas fa-envelope"></i><a class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="mailto:contact@snooze-inn.com">contact@snooze-inn.com</a></li>
                    </ul>
                </div>
                <div class="mt-7">
                    <h4 class="font-nunito font-semibold text-lg lg:text-xl mb-1">SNOOZE INN @ Dickson Road</h4>
                    <ul class="font-nunito font-light flex justify-between flex-col py-1">
                        <li><i class="align-middle fas fa-phone-alt"></i><a class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="tel:+6562915356">+65 6291 5356</a></li>
                        <li><i class="align-middle text-lg fab fa-whatsapp"></i><a rel="noopener" target="_blank" class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="https://wa.me/+6583113655">+65 8311 3655</a></li>
                        <li><i class="align-middle fas fa-envelope"></i><a class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="mailto:dr.snoozeinn@gmail.com">dr.snoozeinn@gmail.com</a></li>
                    </ul>
                </div>
                <div class="mt-7">
                    <h4 class="font-nunito font-semibold text-lg lg:text-xl mb-1">SNOOZE INN @ Dunlop Street</h4>
                    <ul class="font-nunito font-light flex justify-between flex-col py-1">
                        <li><i class="align-middle fas fa-phone-alt"></i><a class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="tel:+6562940583">+65 6294 0583</a></li>
                        <li><i class="align-middle text-lg fab fa-whatsapp"></i><a rel="noopener" target="_blank" class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="https://wa.me/:+6584454649">+65 8445 4649</a></li>
                        <li><i class="align-middle fas fa-envelope"></i><a class="hover:underline transition duration-100 ease-linear hover:tracking-wide ml-2" href="mailto:ds.snoozeinn@gmail.com">ds.snoozeinn@gmail.com</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1dcrV3AJKYVPHWkC5BrHsM7HxDNpXScia" class="row-span-1 mt-4 h-5/6 lg:h-full my-auto w-full"></iframe>
    </div>
</template>

<script>
import Navbar from "../components/Navbar"
import ContactForm from "../components/ContactForm"

export default {
    name: "Contact",
    components:
    {
        Navbar,
        ContactForm
    }
}
</script>

<style scoped>
#contactNavLogo {
    width: 100px;
}

img {
    height: 60vh;
    object-fit: cover;
    object-position: bottom;
}
</style>